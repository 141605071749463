<template>
  <div>
    <div class="md:ml-72 p-3">
      <!-- breadcrumb -->
      <nav  v-scroll-reveal class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
        <ol class="list-none p-0 inline-flex">
          <li class="flex items-center text-blue-500">
            <a href="/" class="text-gray-700">Home</a>
            <svg
              class="fill-current w-3 h-3 mx-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              />
            </svg>
          </li>
          <li class="flex items-center">
            <a href="/admin" class="text-gray-600">Dashboard</a>
             <svg
              class="fill-current w-3 h-3 mx-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              />
            </svg>
          </li>
           <li class="flex items-center">
            <a  class="text-gray-600">Student Applications</a>
        
          </li>
        </ol>
      </nav>
      <!-- breadcrumb end -->
      <v-data-table  v-scroll-reveal
        :headers="headers"
        :items="updated_applications"
        sort-by="last_name"
        class="elevation-1"
      >
      <template v-slot:item.firstName="{ item }">
      
      <a :href="'/admin/profile/'+item.id">{{ item.firstName  }}</a>
        
      
    </template>
    <template v-slot:item.lastName="{ item }">
      
      <a :href="'/admin/profile/'+item.id">{{ item.lastName  }}</a>
        
      
    </template>
      <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Student Applications</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
//import Sidebar from "@/components/admin/Sidebar";
import DataService from "@/authentication/data.service";
export default {
  //components: { Sidebar },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    profiles: null,
    applications: null,
    updated_applications: [],
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: "First Name",
    
        
        value: "firstName",
      },
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Relationship", value: "relation" },
      { text: "Gender", value: "gender" },
      { text: "kind of tutoring", value: "level" },
      { text: "Deliverly", value: "deliverly" },
      { text: "Start Date", value: "start_date" },
      { text: "Message", value: "message" },
      { text: "Curriculum", value: "curriculum" },
      { text: "Other Subjects", value: "other_subjects" },
      
    ],

   
    
    defaultItem: {
      firstName: "",
      lastName: "",
      email: "",
      level: "",
      gender: "",
      start_date: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  async mounted() {
    await DataService.getAllUserProfiles().then(
      (response) => {
        const result = response.data.filter(
          (tutor) => tutor.user_type == "Student"
        );
        this.profiles = result;
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        
      }
    );
    await DataService.getAllStudentApplications().then(
      (response) => {
        this.applications = response.data;
        console.log(this.applications)
      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

      }
    );

    
    for (const application of this.applications) {
     
      const profile = this.profiles.filter(
        (profile) => profile.user.id == application.user
      )[0];
     
      if (profile) {
       
        const tutor_application = {
          firstName: profile.user.first_name,
          id:profile.user.id,
          email: profile.user.email,
          lastName: profile.user.last_name,
          relation: application.relation,
          gender: profile.gender,
          message: application.message,
          level: application.level,
          deliverly: application.deliverly,
          curriculum:application.curriculum,
          other_subjects:application.other_subjects,
          date:this.getDate(application.created_at),
          start_date: application.start_date,
          
        };
       
        this.updated_applications.push(tutor_application);
      }
    }

    //console.log("updated data: ",this.updated_applications)
  },

  methods: {
     getDate(date_string) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date(date_string);
      const today = new Date()
      if (today.getDate() == date.getDate() && 
      today.getMonth == date.getMonth() &&
      today.getFullYear ==  date.getFullYear()){
        return "Today"
      } else{
        return (
        
        date.getDate() +
        ", " +
        months[date.getMonth()] +
        ", " +
        date.getFullYear()+"."
      );
      }
      
    },
    initialize() {
      this.profiles = [
        {
          first_name: "John",
          last_name: "Doe",
          email: "johndoe@example.com",

          contact_number: "+22349859",
          gender: "Male",
        },
      ];
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.updated_applications[this.editedIndex],
          this.editedItem
        );
      } else {
        this.updated_applications.push(this.editedItem);
        alert("new data added successifuly");
      }
      this.close();
    },
  },
};
</script>